
          $base-color: #000081;
          $base-color-light: #def2ff;
          $base-background-color: #def2ff52;
        
table.smart-report {
  th,
  td {
    min-width: 150px;
  }
}
