
          $base-color: #000081;
          $base-color-light: #def2ff;
          $base-background-color: #def2ff52;
        
// Fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
// Theme variables
@import "1-variables/app";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// Theme mixins
@import "2-mixins/background";
@import "2-mixins/button";
@import "2-mixins/modal";
@import "2-mixins/placeholder";
@import "2-mixins/tabs";

// Theme components
@import "3-components/alert";
@import "3-components/badge";
@import "3-components/buttons";
@import "3-components/card";
@import "3-components/collapse";
@import "3-components/dropdown";
@import "3-components/modal";
@import "3-components/nav";
@import "3-components/navbar";
@import "3-components/sidebar";
@import "3-components/table";
@import "3-components/tabs";
@import "3-components/type";

// Theme utilities
@import "4-utilities/background";
@import "4-utilities/cursors";
@import "4-utilities/overflow";
@import "4-utilities/position";
@import "4-utilities/sizing";

// Vendor
@import "5-vendor/quill";

// custom styles
@import "icons";
@import "custom";
@import "login";
@import "inputs";
