html,
body,
#root {
  height: 100%;
}

body {
  background-size: cover;
  background-position-y: 0;
  background-color: $background-color;
  min-width: map-get($grid-breakpoints, "xl") - 0.02px;
  letter-spacing: 0.25px;
}

.admin-layout {
  width: calc(100% - 250px);
  transition: all 0.3s;

  &.closed {
    width: calc(100% - 88px);
    margin-left: 88px;
  }
}

.container-fluid {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fw-bold {
  font-weight: $fw-bold !important;
}

.fw-bolder {
  font-weight: $fw-bolder !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.brand-img {
  border-radius: 0.5rem;
}

.text-underline {
  text-decoration: underline !important;
}

.max-width-20 {
  max-width: 20px;
}

.min-width-50 {
  min-width: 50px;
}

.width-150 {
  width: 150px;
}

.max-width-150 {
  max-width: 150px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-lg {
  min-width: 1200px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-height-50 {
  min-height: 50%;
}

.max-height-100 {
  max-height: 100px;
}

.max-height-400 {
  max-height: 400px;
}

.height-vh {
  height: 100vh;
  &-90 {
    height: 90vh;
  }
  &-80 {
    height: 80vh;
  }
  &-70 {
    height: 70vh;
  }
  &-60 {
    height: 60vh;
  }
  &-50 {
    height: 50vh;
  }
  &-40 {
    height: 40vh;
  }
  &-30 {
    height: 30vh;
  }
  &-20 {
    height: 20vh;
  }
  &-10 {
    height: 10vh;
  }
}

.max-height-vh {
  max-height: 100vh;
  &-90 {
    max-height: 90vh;
  }
  &-80 {
    max-height: 80vh;
  }
  &-70 {
    max-height: 70vh;
  }
  &-60 {
    max-height: 60vh;
  }
  &-50 {
    max-height: 50vh;
  }
  &-40 {
    max-height: 40vh;
  }
  &-30 {
    max-height: 30vh;
  }
  &-20 {
    max-height: 20vh;
  }
  &-10 {
    max-height: 10vh;
  }
}

.height-100 {
  height: 100%;
}

.min-height-100-px {
  min-height: 100px;
}

.min-height-200-px {
  min-height: 200px;
}

.bg-transparent {
  background: transparent !important;
}

.font-size-75 {
  font-size: 0.75rem;
}

.font-size-85 {
  font-size: 0.85rem;
}

.font-size-95 {
  font-size: 0.95rem;
}

.font-size-65 {
  font-size: 0.65rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.align-content-evenly {
  align-content: space-evenly;
}

.word-break-all {
  word-break: break-all;
}

.min-width-200 {
  min-width: 200px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-250 {
  max-width: 150px;
}

.max-width-100 {
  max-width: 100px;
}

.width-170 {
  width: 170px;
}

.width-250 {
  width: 250px;
}

.upper-close {
  position: absolute;
  right: -8px;
  top: -12px;
}

.width-100-px {
  width: 100px;
}

.width-100 {
  width: 100%;
}

.z-index-2 {
  z-index: 2;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.absolute-end {
  position: absolute;
  right: 0;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.conflict i {
  color: #dc3545 !important;
}

.setting-switch {
  .custom-control-label {
    padding-top: 2px;
  }
}

label {
  margin-bottom: 0;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.text-xl {
  font-size: 2.875rem !important;
}

.outline-none,
.outline-none:focus {
  outline: none !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.cursor-default {
  cursor: default !important;
}

.z-index-n1 {
  z-index: -1;
}

.form-group {
  .form-control {
    height: 40px;
  }
}

.dates-slider .slick-current .date {
  background-color: $primary !important;
  color: white !important;
  //transition: background-color linear 250ms;
}

.slick-list::before,
.slick-list::after {
  content: "";
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: 1;
}

.slick-list::before {
  left: 0;
  background: linear-gradient(to left, transparent, white);
}

.slick-list::after {
  right: 0;
  background: linear-gradient(to right, transparent, white);
}

.text-link {
  color: #3b7ddd;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.back-arrow {
  padding: 6px 10px;
}

.work-performed-cards {
  .card {
    min-width: 130px;
    border-radius: $border-radius-default;
  }
}

.box-shadow-card {
  box-shadow: $card-shadow;
}

.border-radius-default {
  border-radius: $border-radius-default !important;
}

.border-radius-md {
  border-radius: $border-radius-md;
}

.border-radius-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-radius-bottom-0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.currency {
  letter-spacing: 0.5px;
}

.note {
  background-color: rgba(253, 244, 166, 1);
  border-radius: $border-radius-default;
}

//carousel bootstrap
.carousel-caption {
  h3 {
    border-radius: $border-radius-default;
    background-color: rgb(255 255 255 / 75%);
  }
}

.rotate {
  transition: transform linear 200ms;
  &-n90 {
    transform: rotate(-90deg);
  }
  &-90 {
    transform: rotate(90deg);
  }
  &-180 {
    transform: rotate(180deg);
  }
  &-270 {
    transform: rotate(270deg);
  }
  &-360 {
    transform: rotate(360deg);
  }
}

.react-transform-component img {
  max-width: 100%;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}
.mt-n2 {
  margin-top: -0.5rem !important;
}
.mt-n3 {
  margin-top: -1rem !important;
}
.mt-n4 {
  margin-top: -1.5rem !important;
}
.mt-n5 {
  margin-top: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}
.mb-n2 {
  margin-bottom: -0.5rem !important;
}
.mb-n3 {
  margin-bottom: -1rem !important;
}
.mb-n4 {
  margin-bottom: -1.5rem !important;
}
.mb-n5 {
  margin-bottom: -3rem !important;
}

.min-height-70 {
  min-height: 70px;
}

.text-hidden {
  max-height: 70px; /* Ajusta la altura según necesites */
  overflow: hidden;
  position: relative;
}

.text-hidden:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px; /* Ajusta la altura del gradiente */
  background: linear-gradient(to bottom, transparent, rgb(253, 244, 166));
}

.tools {
  position: absolute;
  z-index: 100;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.85);
}

.tools-right {
  right: 0;
}

.table-export-container me-3 {
  width: 30px;
}

.height-auto {
  height: auto !important;
}

.text-gray {
  color: #757575 !important;
}

.hidden-scrollbar {
  scrollbar-width: none;
}

.min-width-auto {
  min-width: auto !important;
}

.font-size-regular {
  font-size: 1rem;
}

.fw-regular {
  font-weight: 400;
}

.react-tooltip {
  padding: 5px 10px !important;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  table {
    td {
      padding-bottom: 0;
    }
  }
}

.mx-n2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.general-report-item {
  transition: background-color 0.3s;
}

.profile-user-image {
  height: 54px;
  width: 54px;
  text-indent: 1px;
  overflow: hidden;
  background-color: #e9ecef;
  color: #757575;
}

.text-link-a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
  cursor: pointer;
}
