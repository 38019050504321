
          $base-color: #000081;
          $base-color-light: #def2ff;
          $base-background-color: #def2ff52;
        
.form-control-editable {
  border: 0;
  color: #555;
  width: 100%;
  transition: width 0.2s;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);

  &::placeholder {
    color: #949494;
  }

  &:hover {
    border-bottom: 1px solid #000000;
  }

  &:focus {
    border-bottom: 1px solid #000000;
    outline: 0;
  }
  &.font-size-small {
    font-size: 14px;
    min-width: 140px;
    max-width: 220px;
  }
  &.font-size-medium {
    font-size: 18px;
    min-width: 180px;
    max-width: 480px;
  }
  &.font-size-large {
    font-size: 24px;
    min-width: 210px;
    max-width: 720px;
  }
  &.is-invalid {
    border-color: #dc3545;
    &::placeholder {
      color: #dc354692;
    }
  }
  &:disabled {
    background-color: transparent;
    border-bottom: 0;
  }
}
