.login-bg {
  background-color: #ffffff; /* Blanco */
  background-image: linear-gradient(to bottom, $background-color 60%, $primary-color 40%);
  overflow-y: auto;
}

.login-box {
  width: 540px;
  height: 710px;
  margin-top: 80px;
  background: white;

  box-shadow: 0px -1px 24px rgba(0, 0, 0, 0.12);
  border-radius: 12px;

  img {
    border-radius: 16px !important;
  }

  input {
    outline: none !important;
    width: 311px;
    height: 46px;
    border-top: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 2px solid;
    border-color: #eee;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: border-color ease-in-out 200ms;
    &:focus {
      border-color: #333;
    }
  }

  button {
    font-size: 15px;
    height: 52px;
  }

  .form-container {
    form {
      width: 311px;
    }
  }
}

.powered {
  width: 80px;
}
