input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  &:not(.login-input) {
    -webkit-box-shadow: 0 0 0px 1000px #f4f7fc inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

select.form-control {
  &:disabled {
    background-color: rgba(118, 118, 128, 0.12) !important;
  }
}

input.form-control {
  background-color: rgba(118, 118, 128, 0.12) !important;

  &:disabled {
    background-color: rgba(118, 118, 128, 0.12) !important;
  }

  &:not(.login-input):not(.border-radius-0) {
    border-radius: $border-radius-input-default !important;
  }

  &.form-control-line {
    border: 0;
    background: transparent !important;
    border-bottom: 1px solid #263238;
    border-radius: 0 !important;
    padding: 1rem 0;
    color: #6c757d;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    display: block;
    width: 100%;
    height: 40px;
    box-shadow: 0 0.5px 0 0 #263238;
    outline: none !important;
    &:focus,
    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.search {
  min-width: 130px;
}

.search::before {
  content: "";
  background-image: url("../img/glass_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.form-control-redesign {
  border: none;
  background-color: transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #263238;
  padding: 0;
  color: #6c757d;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 30px;
  border-color: #263238;
  box-shadow: 0 0.5px 0 0 #263238;
  outline: none !important;
}

.modal {
  select.form-control-redesign {
    margin-top: 4px;
  }
}

select.form-control-redesign {
  height: 30px;
}

.form-control-redesign:not(select) {
  height: 40px;
}

select:disabled {
  opacity: 1 !important;
}

.react-select {
  height: 40px;
  color: #6c757d;
  border-color: #263238;
  border-bottom: 1px solid #263238;
  box-shadow: 0 0.5px 0 0 #263238;

  .react-select__single-value {
    color: #6c757d !important;
  }
  .react-select__value-container--has-value,
  .react-select__value-container {
    padding-left: 0;
  }
  .react-select__menu {
    z-index: 1000;
  }
  .react-select__placeholder {
    color: var(--bs-secondary-color) !important;
  }
  &__control {
    background-color: transparent !important;
  }
}

textarea.code {
  font-family: "Courier New", monospace;
  background-color: #f5f5f5;
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow-x: auto;
  width: 100%;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
