@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.modal-colored {
  .modal-header,
  .modal-footer {
    border-color: rgba(255, 255, 255, 0.33);
  }
}

.modal-content {
  border-radius: 1rem;
}

.modal-header {
  border: none;
  padding: 1rem 2.5rem 0;

  .modal-title {
    font-size: 1.375rem;
    small, .small {
      font-size: 0.875rem;
    }
  }
}

.modal-body {
  padding: 1rem 2.5rem;
}

.modal-footer {
  border: none;
  padding: 0 2.5rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  .btn {
    padding: 0.5rem 1rem;
    min-width: 10rem;
    border-radius: 0.75rem;
    margin: 0;

    &.btn-primary {
      min-width: 200px;
    }

    &:not(.btn-primary) {
      min-width: 120px;
    }
  }
}


.modal-sm {
  --bs-modal-width: 420px;
}
