// Icons

$icon-size: 1rem !default;

$icon-sizes: (
  xs: (
    size: 0.75rem,
    align: -0.1em,
  ),
  sm: (
    size: 1rem,
    align: -0.125em,
  ),
  md: (
    size: 1.5rem,
    align: -0.15em,
  ),
  lg: (
    size: 2rem,
    align: -0.175em,
  ),
  xl: (
    size: 3rem,
    align: -0.2em,
  ),
);

@each $name, $props in $icon-sizes {
  .icon-#{$name} {
    font-size: map-get($props, size) !important;
    line-height: map-get($props, size) !important;
    vertical-align: map-get($props, align) !important;
  }
}

@font-face {
  font-family: "crewos-icons";
  src: url("/assets/fonts/crewos-icons.eot?t=1732911369880"); /* IE9*/
  src: url("/assets/fonts/crewos-icons.eot?t=1732911369880#iefix")
      format("embedded-opentype") /* IE6-IE8 */,
    url("/assets/fonts/crewos-icons.woff2?t=1732911369880") format("woff2"),
    url("/assets/fonts/crewos-icons.woff?t=1732911369880") format("woff"),
    url("/assets/fonts/crewos-icons.ttf?t=1732911369880") format("truetype"),
    url("/assets/fonts/crewos-icons.svg?t=1732911369880") format("svg");
}

.icon {
  display: inline-block;
  letter-spacing: 0;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "crewos-icons" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\ea01";
}
.icon-airplay:before {
  content: "\ea02";
}
.icon-alert-circle:before {
  content: "\ea03";
}
.icon-alert-octagon:before {
  content: "\ea04";
}
.icon-alert-triangle:before {
  content: "\ea05";
}
.icon-align-center:before {
  content: "\ea06";
}
.icon-align-justify:before {
  content: "\ea07";
}
.icon-align-left:before {
  content: "\ea08";
}
.icon-align-right:before {
  content: "\ea09";
}
.icon-anchor:before {
  content: "\ea0a";
}
.icon-aperture:before {
  content: "\ea0b";
}
.icon-archive:before {
  content: "\ea0c";
}
.icon-arrow-down-circle:before {
  content: "\ea0d";
}
.icon-arrow-down-left:before {
  content: "\ea0e";
}
.icon-arrow-down-right:before {
  content: "\ea0f";
}
.icon-arrow-down:before {
  content: "\ea10";
}
.icon-arrow-left-circle:before {
  content: "\ea11";
}
.icon-arrow-left:before {
  content: "\ea12";
}
.icon-arrow-right-circle:before {
  content: "\ea13";
}
.icon-arrow-right:before {
  content: "\ea14";
}
.icon-arrow-up-circle:before {
  content: "\ea15";
}
.icon-arrow-up-left:before {
  content: "\ea16";
}
.icon-arrow-up-right:before {
  content: "\ea17";
}
.icon-arrow-up:before {
  content: "\ea18";
}
.icon-arrow:before {
  content: "\ea19";
}
.icon-asteriks:before {
  content: "\ea1a";
}
.icon-at-sign:before {
  content: "\ea1b";
}
.icon-award:before {
  content: "\ea1c";
}
.icon-bar-chart-2:before {
  content: "\ea1d";
}
.icon-bar-chart:before {
  content: "\ea1e";
}
.icon-battery-charging:before {
  content: "\ea1f";
}
.icon-battery:before {
  content: "\ea20";
}
.icon-bell-off:before {
  content: "\ea21";
}
.icon-bell:before {
  content: "\ea22";
}
.icon-bluetooth:before {
  content: "\ea23";
}
.icon-bold:before {
  content: "\ea24";
}
.icon-book-open:before {
  content: "\ea25";
}
.icon-book:before {
  content: "\ea26";
}
.icon-bookmark:before {
  content: "\ea27";
}
.icon-box:before {
  content: "\ea28";
}
.icon-briefcase:before {
  content: "\ea29";
}
.icon-calendar:before {
  content: "\ea2a";
}
.icon-camera-off:before {
  content: "\ea2b";
}
.icon-camera:before {
  content: "\ea2c";
}
.icon-cast:before {
  content: "\ea2d";
}
.icon-ccorner-up-right:before {
  content: "\ea2e";
}
.icon-check-circle:before {
  content: "\ea2f";
}
.icon-check-square:before {
  content: "\ea30";
}
.icon-check:before {
  content: "\ea31";
}
.icon-chevron-down:before {
  content: "\ea32";
}
.icon-chevron-left:before {
  content: "\ea33";
}
.icon-chevron-right:before {
  content: "\ea34";
}
.icon-chevron-up:before {
  content: "\ea35";
}
.icon-chevrons-down:before {
  content: "\ea36";
}
.icon-chevrons-left:before {
  content: "\ea37";
}
.icon-chevrons-right:before {
  content: "\ea38";
}
.icon-chevrons-up:before {
  content: "\ea39";
}
.icon-chrome:before {
  content: "\ea3a";
}
.icon-circle:before {
  content: "\ea3b";
}
.icon-clipboard:before {
  content: "\ea3c";
}
.icon-clock:before {
  content: "\ea3d";
}
.icon-cloud-drizzle:before {
  content: "\ea3e";
}
.icon-cloud-lightning:before {
  content: "\ea3f";
}
.icon-cloud-off:before {
  content: "\ea40";
}
.icon-cloud-rain:before {
  content: "\ea41";
}
.icon-cloud-snow:before {
  content: "\ea42";
}
.icon-cloud:before {
  content: "\ea43";
}
.icon-code:before {
  content: "\ea44";
}
.icon-codepen:before {
  content: "\ea45";
}
.icon-codesandbox:before {
  content: "\ea46";
}
.icon-coffee:before {
  content: "\ea47";
}
.icon-columns:before {
  content: "\ea48";
}
.icon-command:before {
  content: "\ea49";
}
.icon-compass:before {
  content: "\ea4a";
}
.icon-copy:before {
  content: "\ea4b";
}
.icon-corner-down-left:before {
  content: "\ea4c";
}
.icon-corner-down-right:before {
  content: "\ea4d";
}
.icon-corner-left-down:before {
  content: "\ea4e";
}
.icon-corner-left-up:before {
  content: "\ea4f";
}
.icon-corner-right-down:before {
  content: "\ea50";
}
.icon-corner-right-up:before {
  content: "\ea51";
}
.icon-corner-up-left:before {
  content: "\ea52";
}
.icon-cpu:before {
  content: "\ea53";
}
.icon-credit-card:before {
  content: "\ea54";
}
.icon-crop:before {
  content: "\ea55";
}
.icon-crosshair:before {
  content: "\ea56";
}
.icon-database:before {
  content: "\ea57";
}
.icon-delete:before {
  content: "\ea58";
}
.icon-disc:before {
  content: "\ea59";
}
.icon-divide-circle:before {
  content: "\ea5a";
}
.icon-divide-square:before {
  content: "\ea5b";
}
.icon-divide:before {
  content: "\ea5c";
}
.icon-dollar-sign:before {
  content: "\ea5d";
}
.icon-download-cloud:before {
  content: "\ea5e";
}
.icon-download:before {
  content: "\ea5f";
}
.icon-dribbble:before {
  content: "\ea60";
}
.icon-droplet:before {
  content: "\ea61";
}
.icon-edit-2:before {
  content: "\ea62";
}
.icon-edit-3:before {
  content: "\ea63";
}
.icon-edit:before {
  content: "\ea64";
}
.icon-external-link:before {
  content: "\ea65";
}
.icon-eye-off:before {
  content: "\ea66";
}
.icon-eye:before {
  content: "\ea67";
}
.icon-facebook:before {
  content: "\ea68";
}
.icon-fast-forward:before {
  content: "\ea69";
}
.icon-feather:before {
  content: "\ea6a";
}
.icon-figma:before {
  content: "\ea6b";
}
.icon-file-minus:before {
  content: "\ea6c";
}
.icon-file-plus:before {
  content: "\ea6d";
}
.icon-file-text:before {
  content: "\ea6e";
}
.icon-file:before {
  content: "\ea6f";
}
.icon-film:before {
  content: "\ea70";
}
.icon-filter:before {
  content: "\ea71";
}
.icon-flag:before {
  content: "\ea72";
}
.icon-folder-minus:before {
  content: "\ea73";
}
.icon-folder-plus:before {
  content: "\ea74";
}
.icon-folder:before {
  content: "\ea75";
}
.icon-framer:before {
  content: "\ea76";
}
.icon-frown:before {
  content: "\ea77";
}
.icon-gift:before {
  content: "\ea78";
}
.icon-git-branch:before {
  content: "\ea79";
}
.icon-git-commit:before {
  content: "\ea7a";
}
.icon-git-merge:before {
  content: "\ea7b";
}
.icon-git-pull-request:before {
  content: "\ea7c";
}
.icon-github:before {
  content: "\ea7d";
}
.icon-gitlab:before {
  content: "\ea7e";
}
.icon-globe:before {
  content: "\ea7f";
}
.icon-grid:before {
  content: "\ea80";
}
.icon-grip-vertical:before {
  content: "\ea81";
}
.icon-hard-drive:before {
  content: "\ea82";
}
.icon-hard-hat:before {
  content: "\ea83";
}
.icon-hash:before {
  content: "\ea84";
}
.icon-headphones:before {
  content: "\ea85";
}
.icon-heart:before {
  content: "\ea86";
}
.icon-help-circle:before {
  content: "\ea87";
}
.icon-hexagon:before {
  content: "\ea88";
}
.icon-home:before {
  content: "\ea89";
}
.icon-image:before {
  content: "\ea8a";
}
.icon-inbox:before {
  content: "\ea8b";
}
.icon-info:before {
  content: "\ea8c";
}
.icon-instagram:before {
  content: "\ea8d";
}
.icon-italic:before {
  content: "\ea8e";
}
.icon-key:before {
  content: "\ea8f";
}
.icon-layers:before {
  content: "\ea90";
}
.icon-layout:before {
  content: "\ea91";
}
.icon-life-buoy:before {
  content: "\ea92";
}
.icon-link-2:before {
  content: "\ea93";
}
.icon-link:before {
  content: "\ea94";
}
.icon-linkedin:before {
  content: "\ea95";
}
.icon-list:before {
  content: "\ea96";
}
.icon-loader:before {
  content: "\ea97";
}
.icon-lock:before {
  content: "\ea98";
}
.icon-log-in:before {
  content: "\ea99";
}
.icon-log-out:before {
  content: "\ea9a";
}
.icon-mail:before {
  content: "\ea9b";
}
.icon-map-pin:before {
  content: "\ea9c";
}
.icon-map:before {
  content: "\ea9d";
}
.icon-maximize-2:before {
  content: "\ea9e";
}
.icon-maximize:before {
  content: "\ea9f";
}
.icon-meh:before {
  content: "\eaa0";
}
.icon-menu:before {
  content: "\eaa1";
}
.icon-message-circle:before {
  content: "\eaa2";
}
.icon-message-square:before {
  content: "\eaa3";
}
.icon-mic-off:before {
  content: "\eaa4";
}
.icon-mic:before {
  content: "\eaa5";
}
.icon-minimize-2:before {
  content: "\eaa6";
}
.icon-minimize:before {
  content: "\eaa7";
}
.icon-minus-circle:before {
  content: "\eaa8";
}
.icon-minus-square:before {
  content: "\eaa9";
}
.icon-minus:before {
  content: "\eaaa";
}
.icon-monitor:before {
  content: "\eaab";
}
.icon-moon:before {
  content: "\eaac";
}
.icon-more-horizontal:before {
  content: "\eaad";
}
.icon-more-vertical:before {
  content: "\eaae";
}
.icon-mouse-pointer:before {
  content: "\eaaf";
}
.icon-move:before {
  content: "\eab0";
}
.icon-music:before {
  content: "\eab1";
}
.icon-navigation-2:before {
  content: "\eab2";
}
.icon-navigation:before {
  content: "\eab3";
}
.icon-octagon:before {
  content: "\eab4";
}
.icon-package:before {
  content: "\eab5";
}
.icon-paperclip:before {
  content: "\eab6";
}
.icon-pause-circle:before {
  content: "\eab7";
}
.icon-pause:before {
  content: "\eab8";
}
.icon-pen-tool:before {
  content: "\eab9";
}
.icon-percent:before {
  content: "\eaba";
}
.icon-phone-call:before {
  content: "\eabb";
}
.icon-phone-forwarded:before {
  content: "\eabc";
}
.icon-phone-incoming:before {
  content: "\eabd";
}
.icon-phone-missed:before {
  content: "\eabe";
}
.icon-phone-off:before {
  content: "\eabf";
}
.icon-phone-outgoing:before {
  content: "\eac0";
}
.icon-phone:before {
  content: "\eac1";
}
.icon-pie-chart:before {
  content: "\eac2";
}
.icon-play-circle:before {
  content: "\eac3";
}
.icon-play:before {
  content: "\eac4";
}
.icon-plus-circle:before {
  content: "\eac5";
}
.icon-plus-square:before {
  content: "\eac6";
}
.icon-plus:before {
  content: "\eac7";
}
.icon-pocket:before {
  content: "\eac8";
}
.icon-power:before {
  content: "\eac9";
}
.icon-printer:before {
  content: "\eaca";
}
.icon-radio:before {
  content: "\eacb";
}
.icon-refresh-ccw:before {
  content: "\eacc";
}
.icon-refresh-cw:before {
  content: "\eacd";
}
.icon-repeat:before {
  content: "\eace";
}
.icon-rewind:before {
  content: "\eacf";
}
.icon-rotate-ccw:before {
  content: "\ead0";
}
.icon-rotate-cw:before {
  content: "\ead1";
}
.icon-rss:before {
  content: "\ead2";
}
.icon-save:before {
  content: "\ead3";
}
.icon-scissors:before {
  content: "\ead4";
}
.icon-search:before {
  content: "\ead5";
}
.icon-send:before {
  content: "\ead6";
}
.icon-server:before {
  content: "\ead7";
}
.icon-settings:before {
  content: "\ead8";
}
.icon-share-2:before {
  content: "\ead9";
}
.icon-share:before {
  content: "\eada";
}
.icon-shield-off:before {
  content: "\eadb";
}
.icon-shield:before {
  content: "\eadc";
}
.icon-shopping-bag:before {
  content: "\eadd";
}
.icon-shopping-cart:before {
  content: "\eade";
}
.icon-shuffle:before {
  content: "\eadf";
}
.icon-sidebar:before {
  content: "\eae0";
}
.icon-skip-back:before {
  content: "\eae1";
}
.icon-skip-forward:before {
  content: "\eae2";
}
.icon-slack:before {
  content: "\eae3";
}
.icon-slash:before {
  content: "\eae4";
}
.icon-sliders:before {
  content: "\eae5";
}
.icon-smartphone:before {
  content: "\eae6";
}
.icon-smile:before {
  content: "\eae7";
}
.icon-speaker:before {
  content: "\eae8";
}
.icon-square:before {
  content: "\eae9";
}
.icon-star:before {
  content: "\eaea";
}
.icon-stop-circle:before {
  content: "\eaeb";
}
.icon-sun:before {
  content: "\eaec";
}
.icon-sunrise:before {
  content: "\eaed";
}
.icon-sunset:before {
  content: "\eaee";
}
.icon-table:before {
  content: "\eaef";
}
.icon-tablet:before {
  content: "\eaf0";
}
.icon-tag:before {
  content: "\eaf1";
}
.icon-target:before {
  content: "\eaf2";
}
.icon-terminal:before {
  content: "\eaf3";
}
.icon-thermometer:before {
  content: "\eaf4";
}
.icon-thumbs-down:before {
  content: "\eaf5";
}
.icon-thumbs-up:before {
  content: "\eaf6";
}
.icon-toggle-left:before {
  content: "\eaf7";
}
.icon-toggle-right:before {
  content: "\eaf8";
}
.icon-tool:before {
  content: "\eaf9";
}
.icon-trash-2:before {
  content: "\eafa";
}
.icon-trash:before {
  content: "\eafb";
}
.icon-trello:before {
  content: "\eafc";
}
.icon-trending-down:before {
  content: "\eafd";
}
.icon-trending-up:before {
  content: "\eafe";
}
.icon-triangle:before {
  content: "\eaff";
}
.icon-truck:before {
  content: "\eb00";
}
.icon-tv:before {
  content: "\eb01";
}
.icon-twitch:before {
  content: "\eb02";
}
.icon-twitter:before {
  content: "\eb03";
}
.icon-type:before {
  content: "\eb04";
}
.icon-umbrella:before {
  content: "\eb05";
}
.icon-underline:before {
  content: "\eb06";
}
.icon-unlock:before {
  content: "\eb07";
}
.icon-upload-cloud:before {
  content: "\eb08";
}
.icon-upload:before {
  content: "\eb09";
}
.icon-user-check:before {
  content: "\eb0a";
}
.icon-user-minus:before {
  content: "\eb0b";
}
.icon-user-plus:before {
  content: "\eb0c";
}
.icon-user-x:before {
  content: "\eb0d";
}
.icon-user:before {
  content: "\eb0e";
}
.icon-users:before {
  content: "\eb0f";
}
.icon-video-off:before {
  content: "\eb10";
}
.icon-video:before {
  content: "\eb11";
}
.icon-voicemail:before {
  content: "\eb12";
}
.icon-volume-1:before {
  content: "\eb13";
}
.icon-volume-2:before {
  content: "\eb14";
}
.icon-volume-x:before {
  content: "\eb15";
}
.icon-volume:before {
  content: "\eb16";
}
.icon-watch:before {
  content: "\eb17";
}
.icon-wifi-off:before {
  content: "\eb18";
}
.icon-wifi:before {
  content: "\eb19";
}
.icon-wind:before {
  content: "\eb1a";
}
.icon-x-circle:before {
  content: "\eb1b";
}
.icon-x-octagon:before {
  content: "\eb1c";
}
.icon-x-square:before {
  content: "\eb1d";
}
.icon-x:before {
  content: "\eb1e";
}
.icon-youtube:before {
  content: "\eb1f";
}
.icon-zap-off:before {
  content: "\eb20";
}
.icon-zap:before {
  content: "\eb21";
}
.icon-zoom-in:before {
  content: "\eb22";
}
.icon-zoom-out:before {
  content: "\eb23";
}

$icon-activity: "\ea01";
$icon-airplay: "\ea02";
$icon-alert-circle: "\ea03";
$icon-alert-octagon: "\ea04";
$icon-alert-triangle: "\ea05";
$icon-align-center: "\ea06";
$icon-align-justify: "\ea07";
$icon-align-left: "\ea08";
$icon-align-right: "\ea09";
$icon-anchor: "\ea0a";
$icon-aperture: "\ea0b";
$icon-archive: "\ea0c";
$icon-arrow-down-circle: "\ea0d";
$icon-arrow-down-left: "\ea0e";
$icon-arrow-down-right: "\ea0f";
$icon-arrow-down: "\ea10";
$icon-arrow-left-circle: "\ea11";
$icon-arrow-left: "\ea12";
$icon-arrow-right-circle: "\ea13";
$icon-arrow-right: "\ea14";
$icon-arrow-up-circle: "\ea15";
$icon-arrow-up-left: "\ea16";
$icon-arrow-up-right: "\ea17";
$icon-arrow-up: "\ea18";
$icon-arrow: "\ea19";
$icon-asteriks: "\ea1a";
$icon-at-sign: "\ea1b";
$icon-award: "\ea1c";
$icon-bar-chart-2: "\ea1d";
$icon-bar-chart: "\ea1e";
$icon-battery-charging: "\ea1f";
$icon-battery: "\ea20";
$icon-bell-off: "\ea21";
$icon-bell: "\ea22";
$icon-bluetooth: "\ea23";
$icon-bold: "\ea24";
$icon-book-open: "\ea25";
$icon-book: "\ea26";
$icon-bookmark: "\ea27";
$icon-box: "\ea28";
$icon-briefcase: "\ea29";
$icon-calendar: "\ea2a";
$icon-camera-off: "\ea2b";
$icon-camera: "\ea2c";
$icon-cast: "\ea2d";
$icon-ccorner-up-right: "\ea2e";
$icon-check-circle: "\ea2f";
$icon-check-square: "\ea30";
$icon-check: "\ea31";
$icon-chevron-down: "\ea32";
$icon-chevron-left: "\ea33";
$icon-chevron-right: "\ea34";
$icon-chevron-up: "\ea35";
$icon-chevrons-down: "\ea36";
$icon-chevrons-left: "\ea37";
$icon-chevrons-right: "\ea38";
$icon-chevrons-up: "\ea39";
$icon-chrome: "\ea3a";
$icon-circle: "\ea3b";
$icon-clipboard: "\ea3c";
$icon-clock: "\ea3d";
$icon-cloud-drizzle: "\ea3e";
$icon-cloud-lightning: "\ea3f";
$icon-cloud-off: "\ea40";
$icon-cloud-rain: "\ea41";
$icon-cloud-snow: "\ea42";
$icon-cloud: "\ea43";
$icon-code: "\ea44";
$icon-codepen: "\ea45";
$icon-codesandbox: "\ea46";
$icon-coffee: "\ea47";
$icon-columns: "\ea48";
$icon-command: "\ea49";
$icon-compass: "\ea4a";
$icon-copy: "\ea4b";
$icon-corner-down-left: "\ea4c";
$icon-corner-down-right: "\ea4d";
$icon-corner-left-down: "\ea4e";
$icon-corner-left-up: "\ea4f";
$icon-corner-right-down: "\ea50";
$icon-corner-right-up: "\ea51";
$icon-corner-up-left: "\ea52";
$icon-cpu: "\ea53";
$icon-credit-card: "\ea54";
$icon-crop: "\ea55";
$icon-crosshair: "\ea56";
$icon-database: "\ea57";
$icon-delete: "\ea58";
$icon-disc: "\ea59";
$icon-divide-circle: "\ea5a";
$icon-divide-square: "\ea5b";
$icon-divide: "\ea5c";
$icon-dollar-sign: "\ea5d";
$icon-download-cloud: "\ea5e";
$icon-download: "\ea5f";
$icon-dribbble: "\ea60";
$icon-droplet: "\ea61";
$icon-edit-2: "\ea62";
$icon-edit-3: "\ea63";
$icon-edit: "\ea64";
$icon-external-link: "\ea65";
$icon-eye-off: "\ea66";
$icon-eye: "\ea67";
$icon-facebook: "\ea68";
$icon-fast-forward: "\ea69";
$icon-feather: "\ea6a";
$icon-figma: "\ea6b";
$icon-file-minus: "\ea6c";
$icon-file-plus: "\ea6d";
$icon-file-text: "\ea6e";
$icon-file: "\ea6f";
$icon-film: "\ea70";
$icon-filter: "\ea71";
$icon-flag: "\ea72";
$icon-folder-minus: "\ea73";
$icon-folder-plus: "\ea74";
$icon-folder: "\ea75";
$icon-framer: "\ea76";
$icon-frown: "\ea77";
$icon-gift: "\ea78";
$icon-git-branch: "\ea79";
$icon-git-commit: "\ea7a";
$icon-git-merge: "\ea7b";
$icon-git-pull-request: "\ea7c";
$icon-github: "\ea7d";
$icon-gitlab: "\ea7e";
$icon-globe: "\ea7f";
$icon-grid: "\ea80";
$icon-grip-vertical: "\ea81";
$icon-hard-drive: "\ea82";
$icon-hard-hat: "\ea83";
$icon-hash: "\ea84";
$icon-headphones: "\ea85";
$icon-heart: "\ea86";
$icon-help-circle: "\ea87";
$icon-hexagon: "\ea88";
$icon-home: "\ea89";
$icon-image: "\ea8a";
$icon-inbox: "\ea8b";
$icon-info: "\ea8c";
$icon-instagram: "\ea8d";
$icon-italic: "\ea8e";
$icon-key: "\ea8f";
$icon-layers: "\ea90";
$icon-layout: "\ea91";
$icon-life-buoy: "\ea92";
$icon-link-2: "\ea93";
$icon-link: "\ea94";
$icon-linkedin: "\ea95";
$icon-list: "\ea96";
$icon-loader: "\ea97";
$icon-lock: "\ea98";
$icon-log-in: "\ea99";
$icon-log-out: "\ea9a";
$icon-mail: "\ea9b";
$icon-map-pin: "\ea9c";
$icon-map: "\ea9d";
$icon-maximize-2: "\ea9e";
$icon-maximize: "\ea9f";
$icon-meh: "\eaa0";
$icon-menu: "\eaa1";
$icon-message-circle: "\eaa2";
$icon-message-square: "\eaa3";
$icon-mic-off: "\eaa4";
$icon-mic: "\eaa5";
$icon-minimize-2: "\eaa6";
$icon-minimize: "\eaa7";
$icon-minus-circle: "\eaa8";
$icon-minus-square: "\eaa9";
$icon-minus: "\eaaa";
$icon-monitor: "\eaab";
$icon-moon: "\eaac";
$icon-more-horizontal: "\eaad";
$icon-more-vertical: "\eaae";
$icon-mouse-pointer: "\eaaf";
$icon-move: "\eab0";
$icon-music: "\eab1";
$icon-navigation-2: "\eab2";
$icon-navigation: "\eab3";
$icon-octagon: "\eab4";
$icon-package: "\eab5";
$icon-paperclip: "\eab6";
$icon-pause-circle: "\eab7";
$icon-pause: "\eab8";
$icon-pen-tool: "\eab9";
$icon-percent: "\eaba";
$icon-phone-call: "\eabb";
$icon-phone-forwarded: "\eabc";
$icon-phone-incoming: "\eabd";
$icon-phone-missed: "\eabe";
$icon-phone-off: "\eabf";
$icon-phone-outgoing: "\eac0";
$icon-phone: "\eac1";
$icon-pie-chart: "\eac2";
$icon-play-circle: "\eac3";
$icon-play: "\eac4";
$icon-plus-circle: "\eac5";
$icon-plus-square: "\eac6";
$icon-plus: "\eac7";
$icon-pocket: "\eac8";
$icon-power: "\eac9";
$icon-printer: "\eaca";
$icon-radio: "\eacb";
$icon-refresh-ccw: "\eacc";
$icon-refresh-cw: "\eacd";
$icon-repeat: "\eace";
$icon-rewind: "\eacf";
$icon-rotate-ccw: "\ead0";
$icon-rotate-cw: "\ead1";
$icon-rss: "\ead2";
$icon-save: "\ead3";
$icon-scissors: "\ead4";
$icon-search: "\ead5";
$icon-send: "\ead6";
$icon-server: "\ead7";
$icon-settings: "\ead8";
$icon-share-2: "\ead9";
$icon-share: "\eada";
$icon-shield-off: "\eadb";
$icon-shield: "\eadc";
$icon-shopping-bag: "\eadd";
$icon-shopping-cart: "\eade";
$icon-shuffle: "\eadf";
$icon-sidebar: "\eae0";
$icon-skip-back: "\eae1";
$icon-skip-forward: "\eae2";
$icon-slack: "\eae3";
$icon-slash: "\eae4";
$icon-sliders: "\eae5";
$icon-smartphone: "\eae6";
$icon-smile: "\eae7";
$icon-speaker: "\eae8";
$icon-square: "\eae9";
$icon-star: "\eaea";
$icon-stop-circle: "\eaeb";
$icon-sun: "\eaec";
$icon-sunrise: "\eaed";
$icon-sunset: "\eaee";
$icon-table: "\eaef";
$icon-tablet: "\eaf0";
$icon-tag: "\eaf1";
$icon-target: "\eaf2";
$icon-terminal: "\eaf3";
$icon-thermometer: "\eaf4";
$icon-thumbs-down: "\eaf5";
$icon-thumbs-up: "\eaf6";
$icon-toggle-left: "\eaf7";
$icon-toggle-right: "\eaf8";
$icon-tool: "\eaf9";
$icon-trash-2: "\eafa";
$icon-trash: "\eafb";
$icon-trello: "\eafc";
$icon-trending-down: "\eafd";
$icon-trending-up: "\eafe";
$icon-triangle: "\eaff";
$icon-truck: "\eb00";
$icon-tv: "\eb01";
$icon-twitch: "\eb02";
$icon-twitter: "\eb03";
$icon-type: "\eb04";
$icon-umbrella: "\eb05";
$icon-underline: "\eb06";
$icon-unlock: "\eb07";
$icon-upload-cloud: "\eb08";
$icon-upload: "\eb09";
$icon-user-check: "\eb0a";
$icon-user-minus: "\eb0b";
$icon-user-plus: "\eb0c";
$icon-user-x: "\eb0d";
$icon-user: "\eb0e";
$icon-users: "\eb0f";
$icon-video-off: "\eb10";
$icon-video: "\eb11";
$icon-voicemail: "\eb12";
$icon-volume-1: "\eb13";
$icon-volume-2: "\eb14";
$icon-volume-x: "\eb15";
$icon-volume: "\eb16";
$icon-watch: "\eb17";
$icon-wifi-off: "\eb18";
$icon-wifi: "\eb19";
$icon-wind: "\eb1a";
$icon-x-circle: "\eb1b";
$icon-x-octagon: "\eb1c";
$icon-x-square: "\eb1d";
$icon-x: "\eb1e";
$icon-youtube: "\eb1f";
$icon-zap-off: "\eb20";
$icon-zap: "\eb21";
$icon-zoom-in: "\eb22";
$icon-zoom-out: "\eb23";
