
          $base-color: #000081;
          $base-color-light: #def2ff;
          $base-background-color: #def2ff52;
        
.rjsf {
  .field-object {
    padding: 0 !important;
  }
  #root {
    .form-group {
      margin-bottom: 1rem;
    }
    .required {
      color: red;
      margin-left: 0.25rem;
    }
    input[type="checkbox"] {
      margin-right: 0.5rem;
    }
  }
}