.ql-snow .ql-editor {
  min-height: 15rem;
  background: $white;
}

.ql-toolbar {
  font-family: $font-family-base;
  border: none !important;
}

.ql-container {
  font-family: $font-family-base;
  font-size: $font-size-base;
  border: 1px solid #dee2e6 !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
}
