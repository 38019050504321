.ps-sidebar-container {
  overflow: visible !important;

  &:hover {
    .sidebar-toggle {
      opacity: 1;
    }
  }

  .sidebar-toggle {
    opacity: 0;
    z-index: 99;
    width: 20px;
    height: 20px;
    background-color: $primary-color;
    color: $white;
    font-size: 12px;
    cursor: pointer;
    rotate: 0;

    transition: rotate 0.2s linear, opacity 0.2s ease-in-out,
      display 0.5s linear;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    position: fixed;
    top: calc(50% - 10px);
    right: -10px;
  }

  .ps-submenu-expand-icon {
    display: flex;
    span {
      border-right: 1px solid currentcolor;
      border-bottom: 1px solid currentcolor;
      width: 7px;
      height: 7px;
    }
  }

  .ps-menu-root {
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }
  .badge {
    text-indent: 0;
  }
}

.ps-collapsed {
  .ps-submenu-root {
    .ps-menu-button:not(.ps-active):hover {
      background: $primary-light-color !important;
      .ps-menu-icon {
        background: $primary-light-color !important;
        color: $primary-color !important;
      }
    }
  }
  .ps-submenu-root:hover {
    .ps-menu-button:not(.ps-active) {
      background: $primary-light-color !important;
      .ps-menu-icon {
        background: $primary-light-color !important;
        color: $primary-color !important;
      }
    }
  }
  .ps-menu-icon {
    margin-right: 0;
  }
  .ps-menu-label {
    display: none;
  }
  .ps-submenu-content {
    margin-left: -5px !important;
    padding-bottom: 5px;
    .ps-menu-label {
      display: block;
    }
  }
  .ps-submenu-expand-icon {
    display: none !important;
  }
  .ps-category:first-child {
    .ps-menu-button {
      margin-top: 0.55rem;
    }
  }
}

.ps-sidebar-brand {
  background: $ps-sidebar-brand-bg;
  min-height: 170px;
  max-height: 170px;
  position: relative;

  img {
    border-radius: 12px;
    max-height: 80px;
    width: auto;
    max-width: 100%;
  }
}

.ps-sidebar-user {
  min-height: 54px;
  max-height: 54px;
  padding: $ps-sidebar-user-padding;
  background: $ps-sidebar-user-background;
  color: $ps-sidebar-user-color;
  text-align: center;
  line-height: 1.2rem;
  .user-image {
    height: 34px;
    width: 34px;
    text-indent: 1px;
    overflow: hidden;
    background-color: $gray-200;
    color: #757575;
  }
}

.ps-sidebar-powered {
  span {
    font-size: 8px;
    line-height: 18px;
  }
}

.ps-menu-button,
.ps-menu-icon {
  transition: background 0.1s linear;
  transition: color 0.1s linear;
}

.ps-category:first-child {
  .ps-menu-button {
    margin-top: 0.2rem;
  }
}
